<template>
    <base-section id="theme-features">
        <base-section-heading title="Sylvaleo Global Limited Features"
            >Reasons why our customers still do business with us till
            date.</base-section-heading
        >
        <v-container>
            <v-row>
                <v-col
                    v-for="(feature, i, text) in features"
                    :key="i"
                    cols="12"
                    md="6"
                >
                    <base-avatar-card v-bind="feature" align="left" horizontal
                        >{{ text }}}</base-avatar-card
                    >
                </v-col>
            </v-row>
        </v-container>
    </base-section>
</template>

<script>
export default {
    name: 'SectionThemeFeatures',

    data: () => ({
        features: [
            {
                color: 'primary',
                dark: true,
                title: 'Sincerity is Our Watch-Word',
                icon: 'mdi-head-check',
                text:
                    'Our company is managed by sincere and capable hands you definetely will get what you requested.',
            },
            {
                title: 'We are Registered With CAC',
                icon: 'mdi-registered-trademark',
                text:
                    'Our company is incorporated at the Cooperate Affairs Commission (CAC) with No.RC1348254',
            },
        ],
    }),
};
</script>
